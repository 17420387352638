// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-datepicker__input-container,.react-datepicker-ignore-onclickoutside{
  background-color: '#339af0' !important;
  border:none !important;
  /* outline: none !important; */
  padding: "12px" !important;
  width: "150px" !important;

}
.react-datepicker__input-container>input{
  background: #339af0 !important;
  border:none !important;
  /* border:2px solid grey !important; */
  outline: none !important;
  padding: "12px" !important;
  display: flex ;
  text-align: center;
  padding:6px !important;
  color:white;
  border-radius: 50px !important;
  width: 150px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-icon {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,sCAAsC;EACtC,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;EAC1B,yBAAyB;;AAE3B;AACA;EACE,8BAA8B;EAC9B,sBAAsB;EACtB,sCAAsC;EACtC,wBAAwB;EACxB,0BAA0B;EAC1B,cAAc;EACd,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE;aACW;AACb;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n.react-datepicker__input-container,.react-datepicker-ignore-onclickoutside{\n  background-color: '#339af0' !important;\n  border:none !important;\n  /* outline: none !important; */\n  padding: \"12px\" !important;\n  width: \"150px\" !important;\n\n}\n.react-datepicker__input-container>input{\n  background: #339af0 !important;\n  border:none !important;\n  /* border:2px solid grey !important; */\n  outline: none !important;\n  padding: \"12px\" !important;\n  display: flex ;\n  text-align: center;\n  padding:6px !important;\n  color:white;\n  border-radius: 50px !important;\n  width: 150px !important;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.Toastify__toast-icon {\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
